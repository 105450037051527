import { Controller } from '@hotwired/stimulus';

// kana
const FILTER_KANA = /^([ァ-ンヴー]|\s)*$/;

// email
const FILTER_EMAIL = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i;

// phone
const FILTER_PHONE = /^[\d０-９]{10,11}$/;

// Connects to data-controller='entry--efo'
export default class extends Controller {
  static targets = ['valid', 'submitBtn', 'disableBtn', 'form'];
  static classes = ['hide', 'valid', 'invalid', 'requied'];
  static values = {
    'requied': String,
    'valid': String
  };

  connect() {
    this.toggleSubmit()
  }

  submit() {
    this.submitBtnTarget.disabled = true
    const entryButton = document.getElementById('btn-complete-entry')
    const processingButton = document.getElementById('btn-processing')
    entryButton!.style.display = 'none'
    processingButton!.style.display = 'flex'
    this.formTarget.submit()
  }

  validate(evt) {
    const target = evt.target
    const dataset = target.dataset
    const validId = dataset.validId
    const relation = dataset.relation
    const interlock = dataset.interlock

    var invalid = this.checkValid(target)
    this.checkField(target, invalid, validId)

    if (!invalid && relation) {
      invalid = this.checkRelation(relation)
    }

    this.checkLabel(invalid, validId)

    if (interlock) {
      this.checkInterlock(interlock)
    }

    this.toggleSubmit()
  }

  checkRelation(relation) {
    var relation_array = relation.split(',')
    const relationInvalids = []

    for( let i = 0 ; i < relation_array.length ; i ++ ) {
      const relationId = relation_array[i]
      const relationTarget = document.getElementById(relationId)
      if (relationTarget) {
        if (this.checkValid(relationTarget)) {
          return true
        }
      }
    }

    return false
  }

  checkInterlock(interlock) {
    const target = document.getElementById(interlock)
    const dataset = target.dataset
    const validId = dataset.validId
    const relation = dataset.relation

    var invalid = this.checkValid(target)
    this.checkField(target, invalid, validId)

    if (!invalid && relation) {
      invalid = this.checkRelation(relation)
    }

    this.checkLabel(invalid, validId)
  }

  checkValid(target) {
    const value = target.value
    const dataset = target.dataset

    var invalid = false;

    if (!invalid && dataset.presence) {
      invalid = this.validPresence(value)
    }

    if (!invalid && dataset.select) {
      invalid = this.validSelect(value)
    }

    if (!invalid && dataset.multipleChoice) {
      invalid = this.validMultipleChoice(value, target.name, dataset.multipleChoice)
    }

    if (!invalid && dataset.formatKana) {
      invalid = this.validFormatKana(value)
    }

    if (!invalid && dataset.formatEmail) {
      invalid = this.validFormatEmail(value)
    }

    if (!invalid && dataset.formatPhone) {
      invalid = this.validFormatPhone(value)
    }

    if (!invalid && dataset.acceptance) {
      invalid = this.validAcceptance(value)
    }

    if (!invalid && dataset.acceptance) {
      invalid = this.validAcceptance(value)
    }

    return invalid
  }

  checkAllValid() {
    var result = true

    this.validTargets.forEach((el) => {
      let validOption = el.dataset.validOption

      if (validOption) {
        result = result && !el.classList.contains(this.invalidClass)
      } else {
        result = result && el.classList.contains(this.validClass)
      }
    })

    return result
  }

  toggleSubmit() {
    if (this.checkAllValid()) {
      this.submitBtnTarget.classList.remove(this.hideClass)
      this.disableBtnTarget.classList.add(this.hideClass)
    } else {
      this.submitBtnTarget.classList.add(this.hideClass)
      this.disableBtnTarget.classList.remove(this.hideClass)
    }
  }

  checkField(element, invalid, validId) {
    const error = document.getElementById('error_' + validId)
    const elements = document.getElementsByName(element.name)
    if (invalid) {
      elements.forEach((e) => {
        e.classList.remove(this.validClass)
        e.classList.add(this.invalidClass)
      })

      error.innerHTML = this.errorMessage(invalid)
    } else {
      elements.forEach((e) => {
        e.classList.add(this.validClass)
        e.classList.remove(this.invalidClass)
      })
      error.innerHTML = ''
    }
  }

  checkLabel(invalid, validId) {
    const requied = document.getElementById('requied_' + validId)

    if (invalid) {
      if (requied) {
        requied.classList.remove(this.validClass)
        requied.classList.add(this.requiedClass)
        requied.innerHTML = this.requiedValue
      }
    } else {
      if (requied) {
        requied.classList.add(this.validClass)
        requied.classList.remove(this.requiedClass)
        requied.innerHTML = this.validValue
      }
    }
  }

  errorMessage(validType) {
    if (validType === 'presence') {
      return "入力してください"
    } else if (validType === 'select') {
      return "選択してください"
    } else if (validType === 'format_kana') {
      return "カタカナで入力してください"
    } else if (validType === 'format_email') {
      return "メールアドレスの形式（xxx@xxxx.xx）で半角英数を入力してください"
    } else if (validType === 'format_phone') {
      return "10桁もしくは11桁の半角数値（ハイフンなし）で入力してください"
    } else if (validType === 'acceptance') {
      return "ご応募またはお申込みには、個人情報取扱いについて同意いただく必要があります"
    }
  }

  validPresence(value) {
    if (!value.trim()) {
      return 'presence'
    }
  }

  validSelect(value) {
    if (!value.trim()) {
      return 'select'
    }
  }

  validMultipleChoice(value, name, num) {
    const elements = document.getElementsByName(name)

    let valuePresent = false
    for (let element of elements) {
      if (element.checked === true) {
        valuePresent = true
      }
    }

    if (!valuePresent) {
      return 'select'
    }
  }

  validFormatKana(value) {
    const trim_value = value.trim()
    const valid = FILTER_KANA.test(trim_value)
    if (!valid) {
      return 'format_kana'
    }
  }

  validFormatEmail(value) {
    const trim_value = value.trim()
    const valid = FILTER_EMAIL.test(trim_value)
    if (!valid) {
      return 'format_email'
    }
  }

  validFormatPhone(value) {
    const trim_value = value.trim()
    const valid = FILTER_PHONE.test(trim_value)
    if (!valid) {
      return 'format_phone'
    }
  }

  validAcceptance(value) {
    if (value !== '1') {
      return 'acceptance'
    }
  }

  // 離脱ブロック

  // カタカナ自動入力
  
  // 半角・全角自動変換
  
  // 半角・全角自動変換
  
  // メール記号半角自動変換
}
